<template>
    <div class="termly">
        <ul class="termly_head">
            <li @click="headTabIndex = index" :class="{'active': index == headTabIndex}" v-for="(tab, index) in headTab" :key="index">
                {{tab.label}}
            </li>
        </ul>
        <!-- 306多期投注 -->
        <div v-if="headTabIndex == 1">
            <p class="termly_title" :style="getBg(titles[0].img)">
                <span>{{titles[0].text}}</span>
                <span @click="showInfoEvent(0)">{{titles[0].tip}}</span>
            </p>
            <div class="termly_306">
                <ul class="termly_306_tab">
                    <li v-for="(item, index) in termlyLotteryTab" @click="termlyLotteryTabIndex = index" :key="index">
                        <span :class="{'active': index == termlyLotteryTabIndex}">{{item.label}}</span>
                    </li>
                </ul>
                <div class="termly_306_content">
                    <div class="content_head">
                        <img :src="ssqInfo.icon"/>
                        <div>
                            <p>双色球多期<span class="padding_left tip_color_red">商品编号：{{orderNumber}}</span></p>
                            <p class="tip_color_red">
                                <span>{{chooseLotteryKind.add}}</span>
                                <b class="red">{{chooseLotteryKind.note}}</b><span>期/</span><b class="red">{{chooseLotteryKind.money}}</b><span>元</span>
                            </p>
                        </div>
                    </div>
                    <ul class="content_kinds">
                        <li 
                            :class="{content_kinds_active: chooseKindIndex == index}" 
                            v-for="(kind, index) in termlyLotteryKinds" 
                            @click="chooseKindIndex = index"
                            :key="index" 
                            class="content_kinds_item">
                            <p class="red"><b>{{kind.note}}</b>期</p>
                            <p>{{kind.label}}</p>
                        </li>
                    </ul>
                    <div :style="showBallStyle()" class="content_balls">
                        <Ball @click="chooseBall" v-for="(ball, index) in redBalls" :value="ball" :isChoose="true" :key="index"/>
                        <Ball @click="chooseBall" v-for="(ball, index) in blueBalls" color="blue" :value="ball" :isChoose="true" :key="index"/>
                        <span class="content_balls_change" @click="getLottery"></span>
                    </div>
                    <div class="content_button">
                        <button @click="hotGeneMessage(termlyLotteryKinds[chooseKindIndex].id, termlyLotteryKinds[chooseKindIndex].money)" class="red_white_solid">生成短信</button>
                    </div>
                </div>
            </div>
            <p class="termly_title" :style="getBg(titles[1].img)">
                <span>{{titles[1].text}}</span>
                <span @click="showInfoEvent(1)">{{titles[1].tip}}</span>
            </p>
            <ul class="termly_recommend">
                <li class="termly_recommend_info" v-for="(item, index) in recommandTermlyLotteryKinds" :key="index">
                    <span class="info_no">{{termlyLotteryTab[1].order}}{{item.id}}</span>
                    <img :src="ssqInfo.icon"/>
                    <div>
                        <p>双色球包{{item.add}}</p>
                        <p class="termly_recommend_info_tip"><span class="red">{{item.money}}元</span><span>(1注1倍{{item.stage}}期)</span></p>
                        <button @click="commandGeneMessage(item.id, item.money)" class="red_white_solid">生成短信</button>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 自动多期 -->
        <div v-else>
              <p class="termly_title" :style="getBg(titles[2].img)">
                <span>{{titles[2].text}}</span>
                <span @click="showInfoEvent(1)">{{titles[2].tip}}</span>
            </p>
            <ul class="termly_auto">
                <li v-for="(item, index) in lotterys"  :key="index">
                    <div class="termly_auto_head">
                        <img :src="item.url" alt="">
                        <div>
                            <p>{{item.name}}</p>
                            <p><small>{{item.tip}}</small></p>
                        </div>
                    </div>
                    <ul class="termly_auto_buttons">
                        <li v-for="(button, childIndex) in autoButtons" @click="goChoose(button.eventName, item.id)" :key="childIndex+''+index">
                            {{button.label}}
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- 双色球选号弹出框 -->
        <div v-if="showChoose" class="termly_choose" @click.stop = "showChoose = false">
            <div class="termly_choose_content">
                <p>请选择<span class="red">6个</span>红球和<span class="blue">1个</span>篮球</p>
                <ul class="termly_choose_content_balls" @click.stop>
                    <li v-for="(ballLine, index) in chooseBalls" :key="index">
                        <ball 
                            v-for="(ball, ballIndex) in ballLine.showBalls"
                            :value="ball.value"
                            :isChoose="ball.isChoose"
                            @click.stop="clickBall(ballLine, ball)"
                            :color="ball.color" 
                            :key="index+''+ballIndex"/>
                    </li>
                </ul>
                <button :class="{'active': canLottery}" @click="getChooseNumber">确认</button>
            </div>
        </div>
        <!-- 规则说明弹出框 -->
        <div class="termly_info" v-if="showInfo">
            <div class="termly_info_content">
                <h3>{{trueInfo.title}}</h3>
                <div>
                    <p v-for="(info, index) in trueInfo.info" :key="index">
                        {{index+1}}、{{info}}
                    </p>
                </div>
                <span @click="showInfo = false" class="termly_info_content_close"></span>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import { computed, onMounted, reactive, toRefs } from 'vue'
import { ssqGameId, gameIDMap, lotterys, chooseNumberArr } from '../../common-data'
import Ball from '../../components/Ball.vue'
import Footer from '../../components/Footer.vue'
import { betRandomArr, getHeadTabIndex, jumpTo, sendMessage, setHeadTabIndex, judgeIsLogin, fillNumber, getUserInfo } from '../../utils/util'
import { onBeforeRouteLeave } from 'vue-router'
export default {
    name: '',
    components: { Ball, Footer },
    setup() {
        const state= reactive({
                headTab: [
                    { label: "自动多期投注", name: "autoConvest"},
                    { label: "多期投注套餐", name: "306Convest" },
                ],
                // 自动多期按钮
                autoButtons: [
                    {label: '机选', eventName: 'machineChoose'},
                    {label: '自选', eventName: 'autoChoose'},
                ],
                titles: [
                    {text: '热门多期投注', img: require('../../assets/imgs/termly_icon_hot.png'), tip: '规则说明', tipRoute: 0},
                    {text: '精选多期投注', img: require('../../assets/imgs/termly_icon_recommand.png')},
                    {text: '彩种选择', img: require('../../assets/imgs/termly_icon_kinds.png'), tip: '规则说明', tipRoute: 1}
                ],
                infos: [
                    {
                        title: '306多期投注规则说明',
                        info: [
                            '通过本活动页面参与多期投注选号的用户，当方案生效成功则不可终止，且多期投注选号仅支持单式1注1倍。',
                            '热门多期投注:双色球多期投注编号:包月(a3362TC02#号码)、季度(a3362TC03#单式号码)、半年(a3362TC04#单式号码)、全年(a3362TC05#单式号码);',
                            '热门多期投注:多期投注编号后面带“#投注号码”则为固定号码(如: a3362TC02#01020304050607);',
                            '机选号码不支持选号，投注成功我们将您机选号码短信发送给您。',
                            '精选多期投注:仅支持系统机选号码，不可自选号码;',
                            '多期投注投注请在当前奖期截止时间20分钟前下单。'
                        ]
                    },
                    {
                        title: '自动多期投注规则说明',
                        info: [
                            '系统每期自动为您购买相应注数的机选号码或自选号码，直到卡内资金不足或您取消自动多期投注为止。',
                            '一条短信仅能设定1注号码，自动投注下期生效。',
                            '若连续10期自动多期投注扣款失败，您设置的自动多期投注订单将自动取消。'
                        ]
                    }
                ],
                infoIndex: 0,
                showInfo: false,
                headTabIndex: 0,
                // 多期投注类型
                termlyLotteryTab: [
                    { label: "自选", eventName: "chooseSelf", order: 'a3362TC' },
                    { label: "机选", eventName: "chooseMachine", order: 'a3361TC' }
                ],
                termlyLotteryTabIndex: 0,
                // 多期投注种类
                termlyLotteryKinds: [
                    { label: "包周多期", note: "3" , id: '01', money: 6, add: '包周'},
                    { label: "包月多期", note: "13" , id: '02', money: 26, add: '包月'},
                    { label: "季度多期", note: "39", id: '03', money: 78, add: '包季' },
                    // { label: "半年多期", note: "78", id: '04', money: 156, add: '半年' },
                    { label: "全年多期", note: "153", id: '05', money: 306, add: '包年' }
                ],
                chooseKindIndex: 0,
                // 精选多期
                recommandTermlyLotteryKinds: [
                    { label: "包周多期", money: 6, id: "01", stage: 3, add: "周", note: '3期'},
                    { label: "包月多期", money: 26, id: "02", stage: 13, add: "月", note: '13期'},
                    { label: "包季多期", money: 78, id: "03", stage: 39, add: "季", note: '39期' },
                    { label: "全年多期", money: 306, id: "05", stage: 153, add: "年" , note: '153期'}
                ],
                chooseBalls: [
                    {label: '请选择6个红球', type: 'single', showBalls: [], min: 6, max: 6, numberType: true, length: 33, color: 'red'},
                    {label: '请选择1个蓝球', type: 'single', showBalls: [], min: 1, max: 1, numberType: true, length: 16, color: 'blue'}
                ],
                machineOrder: ['a3361TC', 'a3362TC'],
                redBalls: ['01', '02', '03', '04', '05', '08'],
                blueBalls: ['02'],
                showChoose: false,
                money: 0
                // lotteryIndex: 0,
        })
        const trueInfo = computed(() => {
            return state.infos[state.infoIndex]
        })
        const chooseLotteryKind = computed(() => {
            return state.termlyLotteryKinds[state.chooseKindIndex]
        })
        const orderNumber = computed(() => {
            return `${state.termlyLotteryTab[state.termlyLotteryTabIndex].order}${chooseLotteryKind.value.id}`
        })
        const canLottery = computed(() => {
            let trueNum = 0
            state.chooseBalls.forEach((ballLine) => {
                trueNum = ballLine.max == ballLine.showBalls.filter(ele => ele.isChoose).length ? trueNum+1 : trueNum
            })
            return trueNum == state.chooseBalls.length
        })
        onBeforeRouteLeave(() => {
            console.log('leave')
            setHeadTabIndex(state.headTabIndex)
        })
        onMounted(() => {
            state.headTabIndex = getHeadTabIndex() || 0
        })
        function _initBalls(info) {
            for(let i = 0; i < info.length; i++) {
                info.showBalls.push({value: `${i < 9 ? '0'+(i+1) : i+1}`, color: info.color, isChoose: false})
            }
        }
        function initBalls() {
            state.chooseBalls.forEach(ele => {
                _initBalls(ele)
            })
        }
        initBalls()
        function showBallStyle() {
            const params = {
                height: `${state.termlyLotteryTabIndex == 0 ? '40px' : '0px'}`,
                paddingTop: `${state.termlyLotteryTabIndex == 0 ? '10px' : '0px'}`
            }
            return params
        }
        // 显示规则
        function showInfoEvent(index) {
            state.infoIndex = index
            state.showInfo = true
        }
        // 球球点击事件
        function clickBall(ballLine, item) {
            let selectedLen = 0, firstIndex = -1
            ballLine.showBalls.forEach((ele, index) => {
                if (ele.isChoose) {
                    firstIndex = firstIndex == -1 ? index : firstIndex
                    selectedLen += 1
                }
            })
            if (item.isChoose) {
                item.isChoose = false
            } else if((selectedLen == ballLine.max || selectedLen > ballLine.max) && !item.isChoose) {
                ballLine.showBalls[firstIndex].isChoose = false
                item.isChoose = true
            } else {
                item.isChoose = true
            }
        }
        // 获取随机一注
        const mode = chooseNumberArr.get(ssqGameId).modes[0]
        function fill(arr) {
            let _arr = []
            arr.forEach(ele => {
                _arr.push(fillNumber(1, parseInt(ele)))
            })
            return _arr
        }
        function getLottery() {
            const balls = betRandomArr(mode)
            state.redBalls = fill(balls[0])
            state.blueBalls = fill(balls[1])
            // console.log(fill(state.redBalls))
            // console.log(balls)
        }
        getLottery()
        // 获取用户选择的号码
        function getChooseNumber() {
            if (!canLottery.value) {
                return
            }
            let _arr = []
            const showBalls = state.chooseBalls.map(ele => ele.showBalls)
            showBalls.forEach((ele, index) => {
                _arr = []
                ele.forEach(ball => {
                    if(ball.isChoose) {
                        _arr.push(ball.value)
                    }
                })
                if (index == 0) {
                    state.redBalls = _arr
                } else {
                    state.blueBalls = _arr
                }
            })
        }
        // 渲染选择的球球
        function renderBalls() {
            const showBalls = state.chooseBalls.map(ele => ele.showBalls)
            showBalls.forEach(line => {
                line.forEach(ele => ele.isChoose = false)
            })
             showBalls[0].forEach(ele => {
                 if(state.redBalls.indexOf(ele.value) > -1) {
                     ele.isChoose = true
                 }
             })
             showBalls[1].forEach(ele => {
                 if(state.blueBalls.indexOf(ele.value) > -1) {
                     ele.isChoose = true
                 }
             })
        }
         // 手动选择球球
        function chooseBall() {
            state.showChoose = true
            renderBalls()
        }
        // 双色球信息
        const ssqInfo = gameIDMap.get(ssqGameId)
        // 生成短信
        // 热门多期生成短信
        function hotGeneMessage(id, money) {
            // judgeIsLogin()
            state.money = money
            const index = state.termlyLotteryTabIndex
            const arr = index == 0 ? `${state.redBalls.join('')}${state.blueBalls.join('')}` : ''
            const content = `${orderNumber.value}${index == 0 ? `#${arr}` : ''}`
            console.log(content)
            innerSendMessage(content)
        }
        // 精选多期投注生成短信
        function commandGeneMessage(id, money) {
            // judgeIsLogin()
            state.money = money
            const content = `${state.termlyLotteryTab[1].order}${id}`
            innerSendMessage(content)
        }
        // 自动多期投注去选球
        function goChoose(eventName, gameId) {
            judgeIsLogin()
            jumpTo(`/choosenumber?gameId=${gameId}&machineAuto=${eventName == 'machineChoose'}`)
        }
        function getBg(img) {
            return {
                background: `url(${img}) left center no-repeat`,
                backgroundSize: '15px',
                paddingLeft: '20px'
            }
        }
        // 生成短信
        async function innerSendMessage(content) {
            try {
                const isLogin = await judgeIsLogin()
                if(isLogin) {
                    sendMessage(content, state.money)
                } else {
                    jumpTo('/?login=true')
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 判断是否在白名单
        async function judegeIsInWhiteList() {
            try {
                const info = await getUserInfo(true)
                if (info.open != 1) {
                    jumpTo('/')
                }
            } catch(e) {
                console.error(e)
            }
        }
        judegeIsInWhiteList()
        return {
            ...toRefs(state),
            ssqInfo,
            lotterys,
            showBallStyle,
            getLottery,
            hotGeneMessage,
            commandGeneMessage,
            goChoose,
            chooseLotteryKind,
            getBg,
            orderNumber,
            clickBall,
            canLottery,
            getChooseNumber,
            chooseBall,
            trueInfo,
            showInfoEvent
        }
    },
}
</script>
<style lang='scss' scoped>
$pink-bg: #ffe9e9;
$line-color: #ffadad;
.padding_left {
    padding-left: 15px;
}
.tip_color_red {
    color: #9d483f;
}
.termly {
    padding: $margin-big;
    margin-top: 5px;
    &>div {
        padding-top: 10px;
    }
    &_head {
        display: flex;
        border-radius: $box-radius-small;
        overflow: hidden;
        font-size: $font-big;
        margin-top: 10px;
        overflow: hidden;
        li {
            width: 50%;
            text-align: center;
            background: $line-gray;
            padding: 8px;
        }
        .active {
            background: $theme-dark;
            color: #fff;
            font-weight: 700;
        }
    }
    &_title {
        padding: 10px 0 10px 0;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        font-size: $font-big;
        span {
            &:nth-child(2) {
                font-weight: 500;
                color: $gray-light;
                font-size: $font-middle;
            }
        }
    }
    // 306多期投注
    &_306 {
         :deep .ball {
            font-size: $font-max;
            width: 40px;
            line-height: 40px;
            height: 34px;
            // line-height: 40px;
        }
        background: $pink;
        border: $line-color 1px solid;
        border-radius: $box-radius-small;
        &_tab {
            font-size: $font-big;
            display: flex;
            position: relative;
            justify-content: space-around;
            color: $gray-light;
            border-bottom: solid $line-color 1px;
            position: relative;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 1px;
                        top: 10px;
                        bottom: 7px;
                        background: $line-color;
                    }
            span {
                display: inline-block;
                padding: 9px 0 6px 0;
            }
            .active {
                position: relative;
                color: $theme-dark;
                // border-bottom: solid 2px $theme-dark;
                margin-bottom: -1px;
                font-weight: 700;
                &::after {
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    bottom: -1px;
                    height: 4px;
                    background: $theme-dark;
                    border-radius: $box-radius-small;
                }

            }
        }
        &_content {
            .content_head {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: $margin-big;
                padding-top: 15px;
                img {
                    width: 50px;
                }
                div {
                    padding-left: 8px;
                    &>p {
                        color: #821409;
                        font-size: $font-big;
                        font-weight: 700;
                        &>b {
                            font-size: $font-middle;
                        }
                        &>span {
                            font-weight: 500;
                            font-size: $font-small;
                        }
                    }
                }
            }
            .content_kinds {
                padding: 0 $margin-big 0px $margin-big;
                display: flex;
                justify-content: space-between;
                text-align: center;
                &_active {
                    position: relative;
                    overflow: hidden;
                    box-sizing: border-box;
                    border: solid $theme-dark 1px;
                    &::after{
                        position: absolute;
                        display: inline-block;
                        content: '';
                        width: 15px;
                        height: 30px;
                        bottom: 0;
                        right: -1px;
                        background: url('../../assets/imgs/tangle-icon.png') right bottom no-repeat;
                        background-size: contain;
                    }
                }
                li {
                    font-size: $font-small;
                    margin-right: 10px;
                    padding: 8px 0;
                    border-radius: $box-radius-small;
                    width: 25%;
                    p {
                        &:last-child{
                            color: #6F0000;
                            line-height: 1em;
                            padding-top: 2px;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    b {
                        font-size: $font-middle;
                    }
                    background: $pink-bg;
                }
            }
            .content_balls {
                display: flex;
                padding: 0px $margin-big*2 0 $margin-big;
                align-items: center;
                transition: height 0.2s;
                height: 50px;
                overflow: hidden;
                // justify-content: space-between;
                &_change {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    padding-left: 20px;
                    background: url('../../assets/imgs/home_refresh.png') center center no-repeat;
                    background-size: contain;
                }
            }
            .content_button {
                margin: $margin-big;
                margin-bottom: $margin-big*1.5;
                 button {
                    font-size: $font-big;
                    text-align: center;
                    width: 100%;
                    border-radius: $box-radius-small;
                    padding: 10px 0;
                    margin-top: 5px;
                }
            }
        }
    }
    // 多期投注精选多期投注
    &_recommend {
        display: flex;
        flex-wrap: wrap;
        &_info {
            display: flex;
            align-items: flex-start;
            background: #fff;
            border-radius: $box-radius-small;
            padding: $margin-big*2 0px $margin-big 5px;
            width: 47%;
            margin-bottom: 2%;
            overflow: hidden;
            position: relative;
            border: solid $line-gray 1px;
            &:nth-child(2n) {
                margin-left: 2%;
            }
            .info_no {
                position: absolute;
                top: 0;
                right: -3px;
                font-size: $font-less;
                color: #919191;
                background-color: #e7e7e7;
                padding: 3px 15px;
                border-radius: 0 0 0 18px;
            }
            img {
                width: 50px;
                margin-right: 5px;
            }
            button {
                width: 78px;
                border-radius: $radius;
                margin-top: 10px;
                font-size: $font-small;
                padding: 5px 0px;
            }
            div {
                padding-top: 5px;
            }
            &_tip {
                font-size: $font-less;
                color: $gray-light;
                font-weight: 700;
                span {
                    &:last-child{
                        font-weight: 500;
                    }
                }
            }
        }
    }
    // 自动多期投注
    &_auto {
        display: flex;
        flex-wrap: wrap;
        &>li {
            background: #fff;
            width: 48.5%;
            border-radius: $radius;
            box-sizing: border-box;
            margin-bottom: 3%;
            padding: $margin-big 0 $margin-big*1.4 $margin-big;
            border: solid 1px $line-gray;
            &:nth-child(2n) {
                margin-left: 3%;
            }
            // .auto_active {
            //     background: #FFF9F9;
            //     border: solid $theme-dark 1px;
            // }
            // &:first-child {
               
            // }
        }
        &_head {
            display: flex;
            align-items: center;
            img {
                width: 55px;
                height: 55px;
                margin-right: 7px;
            }
            div {
                p {
                    &:first-child {
                        font-size: $font-big;
                    }
                }
                small {
                    color: $gray-light;
                }
            }
        }
        &_buttons {
            display: flex;
            padding-top: 13px;
            li {
                background: $bg-gray;
                width: 47%;
                text-align: center;
                padding: 4px 0;
                border-radius: $radius;
                background: $theme-dark;
                color: #fff;
                &:last-child{
                    margin-left: 3%;
                    background: #e5e5e5;
                    color: #505050;
                }
            }
        }
    }
    &_choose {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(#000, 0.3);
        &_content {
            position: relative;
            background: #fff;
            width: 80%;
            margin-left: 10%;
            margin-top: 5%;
            // height: 80%;
            overflow: hidden;
            border-radius: $box-radius-small;
            &>p {
                position: absolute;
                left: 15px;
                top: 15px;
            }
            &_balls {
                margin-top: 40px;
                margin-bottom: 60px;
                width: 100%;
                height: 350px;
                overflow: auto;
                li {
                    padding: 15px 0 12px 15px;
                    &:nth-child(1) {
                        padding-top: 0px;
                    }
                    display: flex;
                    flex-wrap: wrap;
                    :deep .ball {
                        margin: 12px 12px 0 0;
                        &:nth-child(6) {
                            margin-right: 0;
                        }
                    }
                }
            }
            button {
                position: absolute;
                text-align: center;
                bottom: 10px;
                width: 88%;
                left: 6%;
                background: #e5e5e5;
                color: #999;
                padding: 9px 0;
                border-radius: $box-radius-small;
            }
            .active {
                color: #fff;
                background: $theme-dark;
            }
        }
    }
    &_info {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($color: #000000, $alpha: 0.3);
        &_content {
            position: relative;
            background: #fff;
            width: 80%;
            height: 65%;
            
            // overflow: auto;
            border-radius: $box-radius-small;
            margin-left: 10%;
            margin-top: 20%;
            padding: 50px 15px 20px 15px;
            box-sizing: border-box;
            h3 {
                position: absolute;
                top: 20px;
                left: 50%;
                transform: translateX(-50%);
                font-size: $font-middle;
                text-align: center;
                padding: 5px 0;
            }
            &>div {
                overflow: auto;
                height: 100%;
                padding-top: 10px;
            }
            p {
                line-height:2em;
                margin-bottom: 5px;
                font-size: $font-small;
            }
            &_close {
                // position: fixed;
                position: absolute;
                right: 10px;
                top: 10px;
                width: 15px;
                height: 15px;
                background: url('../../assets/imgs/cross-gray.png') center center no-repeat;
                background-size: contain;
        }
        }
    }
}
</style>